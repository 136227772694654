import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"
import "./header.css"
import logo from "../content/images/globe.png"

const Header = () => (
  <header >
    <ul className="menu">
      <li className="section-title menu-item"><AnchorLink to="/#work" className="noLinkStyling">WORK</AnchorLink></li>
      <li className="section-title menu-item"><AnchorLink to="/#blog" className="noLinkStyling">BLOG</AnchorLink></li>
      <li className="section-title menu-item">
        <Link to="/" className="noLinkStyling">
          <img src={logo} alt = "website logo as a globe" style={{ width: 50 }}></img>
        </Link>
      </li>
      <li className="section-title menu-item"><AnchorLink to="/#talks" className="noLinkStyling">TALKS</AnchorLink></li>
      <li className="section-title menu-item"><Link to="/about" className="noLinkStyling">ABOUT</Link></li>
    </ul>
  </header>
)

export default Header

import React from "react"

const Footer = () => (
  <footer className="very-small-font">
  Made by Raluca sometime in 2020 with the great
  {` `}
  <a href="https://www.gatsbyjs.com">Gatsby</a>.
  The awesome illustrations are created
  by <a href='https://twitter.com/NinaLimpi'>Katerina Limpitsouni</a> on <a href='https://undraw.co/'>undraw.co</a>.
  </footer>
)

export default Footer